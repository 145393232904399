import React from 'react';
import styles from './ThankYouPage.module.css';

const ThankYouPage = () => {
  const handleReturnHome = () => {
    window.location.href = '/'; // Change to your home URL
  };

  return (
    <div className={styles.thankYouPage}>
      <div className={styles.thankYouContainer}>
        <h1 className={styles.thankYouMessage}>Thank You so much, Jade will appreciate you!</h1>
        <button className={styles.returnHomeButton} onClick={handleReturnHome}>
          Return Home
        </button>
      </div>
    </div>
  );
};

export default ThankYouPage;
