// SocialMediaLinks.js
import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import the desired icons
import './SocialMediaLinks.css'; // Make sure to create this CSS file

const SocialMediaLinks = () => {
  return (
    <div className="social-media-frame">
      <h2 className="social-media-title">Social Media</h2>
      <div className="social-media-container">
        <a href="https://www.facebook.com/share/p/CGXC37Rhgitok2KV/" target="_blank" rel="noopener noreferrer" className="social-link">
          <FaFacebookF /> Carmen Desai
        </a>
        <a href="https://www.facebook.com/share/p/e3boACEg5CAgGtv8/" target="_blank" rel="noopener noreferrer" className="social-link">
          <FaFacebookF /> Melody Williams
        </a>
      </div>

      <div className="contact-info">
        If anyone can help or offer advice, we would greatly appreciate it. 
        Contact us here: 
        <a href="mailto:melodywilliams681@gmail.com" className="email-link"> melodywilliams681@gmail.com</a>
      </div>
    </div>
  );
};

export default SocialMediaLinks;