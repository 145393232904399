import React, { useState, useEffect } from 'react';
import './styles.css'; // Ensure you create this CSS file
import SocialMediaLinks from './SocialMediaLinks'; 

const MainPage = () => {
  const [totalDonated, setTotalDonated] = useState(0);
  const [donations, setDonations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const donationGoal = 5000; // Set your donation goal here
  
  useEffect(() => {
    // Function to fetch donations from the API
    const fetchDonations = async () => {
      try {
        const response = await fetch('https://api.helpjade.xyz/get_donations');
        const data = await response.json();
        setDonations(data);
        const total = data.reduce((sum, donation) => sum + donation.amount, 0);
        setTotalDonated(total);
      } catch (error) {
        console.error('Error fetching donations:', error);
      }
    };

    // Fetch once when component mounts
    fetchDonations();

    // Set up interval to fetch donations every 30 seconds
    const intervalId = setInterval(fetchDonations, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleDonate = () => {
    const stripeLink = `https://buy.stripe.com/dR6eWW4DE40x7Pa3cf`; // Stripe amount is in cents
    window.location.href = stripeLink;
  };

  const completionPercentage = Math.min((totalDonated / donationGoal) * 100, 100);

  const formatNumber = (num) => {
    return `NZ$${num.toLocaleString('en-NZ', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const formatDonationDate = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - new Date(date)) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return `${seconds} seconds ago`;
    if (minutes < 60) return `${minutes} minutes ago`;
    if (hours < 24) return `${hours} hours ago`;
    if (days < 30) return `${days} days ago`;
    if (months < 12) return `${months} months ago`;
    return `${years} years ago`;
  };

  const donationsPerPage = 5;

  const displayDonations = () => {
    const startIndex = currentPage * donationsPerPage;
    const endIndex = Math.min(startIndex + donationsPerPage, donations.length);
    return donations.slice(startIndex, endIndex);
  };

  const nextDonations = () => {
    if ((currentPage + 1) * donationsPerPage < donations.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevDonations = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
    
  
    return (
      <div className="App">
        <h1 className="title">Support Jade: A Mother's Fight for Her Baby</h1>
        <div className="content">
          <blockquote>
            <p className="description1">
              Jade is fighting a legal battle for her baby to come home and is
              seeking financial help towards lawyer fees and living costs.
            </p>
          </blockquote>
          <blockquote className="red-blockquote">
            <p className="description1">
              This page is operated and being ran by Jades Sister (Melody-Jean Williams)
            </p>
          </blockquote>
          
          <div className="image-container">
            <img
              className="image"
              src="https://media.discordapp.net/attachments/1292375140130099271/1294469966627733504/1.png?ex=670b209a&is=6709cf1a&hm=c61e0810dbe51a13dcf9ccb1747e3145811466e14e94d638232230a27e521332&=&format=webp&quality=lossless"
              alt="Jade and her baby"
            />
            
            <div className="description2">
              <div className="donation-container">
                <div className="donation-section">
                  <h2>Donation Goal</h2>
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{
                        width: `${completionPercentage}%`,
                      }}
                    />
                  </div>
                  <div className="donation-info">
                    <span className="amount-raised">
                      Total Donated: <span className="highlight">{formatNumber(totalDonated)}</span>
                    </span>
                    <span className="goal-amount">
                      Goal: <span className="highlight">{formatNumber(donationGoal)}</span>
                    </span>
                  </div>
                  <button onClick={handleDonate} className="donate-button">
                    Donate
                  </button>
                </div>
                <div className="donations-section">
                  <h2>Recent Donations</h2>
                  <div className="donations-list">
                    {displayDonations().map((donation, index) => (
                      <div className="donation-item" key={index}>
                        <span className="donation-name">{donation.name}</span>
                        <span className="donation-date">{formatDonationDate(donation.date)}</span>
                        <span className="donation-amount">{formatNumber(donation.amount)}</span>
                      </div>
                    ))}
                  </div>
                  <div className="pagination-controls">
                    <button className="back-button" onClick={prevDonations} disabled={currentPage === 0}>Back</button>
                    <span className="pagination-info">Page {currentPage + 1}</span>
                    <button className="next-button" onClick={nextDonations} disabled={(currentPage + 1) * donationsPerPage >= donations.length}>Next</button>
                  </div>
                </div>
              </div>
              <p>
                My sister Jade has been living in Brisbane, Australia for the
                last 2 years and had her first baby in January. Her relationship
                ended in May, leaving her with nowhere to stay, no income, and no
                family support. We sent her tickets to Perth where our aunty
                lives.
              </p>
              <p>
                The baby's father, on whom she had a restraining order, started
                court proceedings. Jade applied for legal aid immediately, but two
                days before she was due in court, her application was denied.
                Unable to get legal representation due to financial hardship, she
                asked for an adjournment, which was denied. She appeared in court
                alone.
              </p>
              <p>
                The court ruled that the baby be returned to Brisbane and has
                allocated 2 hours of supervised visitation per week for Jade. This
                means she would have to live in Brisbane with no income, no
                accommodation, and no family support. To make matters worse, the
                restraining order is invalid in Brisbane, offering no protection.
              </p>
              <p>
                Our mother is sending her as much money as possible, but we're
                struggling. We've managed to secure a lawyer, but paying for
                legal fees and covering her living costs if she moves to Brisbane
                is beyond our financial ability. Although it might be better for
                her to return home and continue her fight from here, she risks
                being accused of abandoning her baby. It's heartbreaking.
              </p>
              <SocialMediaLinks/>
            </div>
          </div>
        </div>
      </div>
    );
  };  

export default MainPage;
